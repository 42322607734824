// BUTTONS-ONCOLOGY.SCSS

.btn-teal {
  background-color: $aon-teal;
  color: $white;

  &:hover {
    background-color: lighten($aon-teal, 5%);
    color: $white;
  }
  &:visited {
    background-color: $grey-mid-light !important;
  }
}

.btn-filters {
  background-color: $white;
  color: black;
  border: #005BAA solid 1px;
  padding: 3px 15px;
  margin: 5px;

  &:hover {
    background-color: #E9F9FF;
  }
  &.selected {
    background-color: #E9F9FF;
    border: #005BAA solid 2px;
  }
}

.btn-filters-oncology {
  background-color: $white;
  color: black;
  border: #98002D solid 1px;
  padding: 3px 15px;
  margin: 5px;

  &:hover {
    background-color: #F9EFF2;
  }
  &.selected {
    background-color: #F9EFF2;
    border: #98002D solid 2px;
  }
}

.btn-dsi-blue {
  background-color: #005BAA;
  color: $white;

  &:hover, {
    background-color: white;
    color: #005BAA;
    border: #005BAA solid 2px;
  }
}

.btn-dsi-review{
  font-size: 14px;
  &:hover{
    cursor: pointer;
    text-decoration: none;
  }
}

.btn-dsi-visited {
    background-color: white;
    color: #005BAA;
    border: #005BAA solid 2px;

  &:hover{
    background-color: #005BAA;
    color: $white;
  }

}


.btn-dsi-view {
  background-color: white;
  color: #005BAA;
  border: #005BAA solid 1px;
  padding: 2px 20px;
  border-radius: 5px;

  &:hover{
    background-color: #005BAA;
    color: $white;
  }

}
.btn-continue{
  padding: 5px;
  border: none;
  cursor: pointer;

  &:hover{
    text-decoration: underline;

    .continue-circle {
      fill: lighten($new-blue, 12%);
      //stroke: $new-blue;
    }
    .continue-arrow {
      //fill: $new-blue;
    }
  }
}
.btn-red-outline{
  border: $dark-red 1px solid;
  background-color: $white;

  &:hover {
    background-color: lighten($dark-red, 5%);
    color: $white;
  }
}
.btn-blue {
  background-color: $aon-blue;
  color: $white;

  &:hover {
    background-color: lighten($aon-blue, 5%);
    color: $white;
  }
}

.btn-red {
  background-color: $dark-red;
  color: $white;

  &:hover {
    background-color: lighten($dark-red, 5%);
    color: $white;
  }
}

.btn-yellow {
  background-color: $aon-yellow;
  color: $white;

  &:hover {
    background-color: lighten($aon-yellow, 5%);
    color: $white;
  }
}

.btn-grey-light {
  background-color: $grey-light;
  color: $grey-text;

  &:hover {
    background-color: lighten($grey-light, 5%);
    color: $grey-text;
  }
}

.btn-grey-mid {
  background-color: $grey-mid-dark;
  color: $white;

  &:hover {
    background-color: lighten($grey-mid-dark, 5%);
    color: $white;
  }
}

.btn-grey-dark {
  background-color: $grey-text;
  color: $white;

  &:hover {
    background-color: lighten($grey-text, 5%);
    color: $white;
  }
}

.btn-white {
  background-color: $white;
  color: $grey-mid-light;

  &:hover {
    background-color: $white;
    color: lighten($grey-mid-light, 5%);
  }
}

.btn-white-alt {
  background-color: $white;
  color: $grey-text;

  &:hover {
    background-color: $white;
    color: lighten($grey-text, 5%);
  }
}

.btn.dropdown-toggle {
  color: $white;
  padding: 0px 0px;
  font-size: 14px;
  font-family: "Roboto Medium", Helvetica, sans-serif;
}

.btn-explore,
.btn-show,
.btn-resume,
.btn-review,
.btn-add,
.btn-search {
  padding: 2px 16px;
  font-family: "Roboto Regular", Helvetica, sans-serif;
}

.btn-magnifying{
  background-color: #005BAA;
  background-image: url("https://learning-platform-dsi.s3.amazonaws.com/search-icon.png");
  width: 70px;
  background-size: 22px 22px;
  background-position: center;
  background-repeat: no-repeat;

  &.oncology{
    background-color: #262836;
  }
}



.btn-launch,
.btn-cog,
.btn-all {
  padding: 2px 16px;
  font-family: "Roboto Medium", Helvetica, sans-serif;
}

.progress-pill {
  width: 120px;
  background-color: $white;
  border: 1px solid $grey-bg;
  border-radius: 20px;
  height: 20px;
  font-size: 9px;
  text-align: center;
  padding-top: 3px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 20px;

  &.active {
    background-color: $grey-bg;
  }

  &.null {
    visibility: hidden;
  }
}

// to offset page anchors from fixed top-nav
a.anchor {
  display: block;
  position: relative;
  top: -79px;
  visibility: hidden;
}
