.course-nav-top{
  background-color: $new-blue;
  height: 50px;
  padding-left: 50px;
  padding-right: 50px;
  display: grid;
  grid-template-columns: auto 150px;
  align-items: center;

  @media screen and (max-width: 767px) {
    grid-template-columns: auto 95px;
    height: auto;
    padding: 10px 50px;
  }

  .course-nav-titles{
    color: white;
    display: flex;

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  .journey-title{
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (max-width: 767px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .resource-title{
    padding-left: 20px;
    font-weight: 600;

    @media screen and (max-width: 767px) {
      padding-left: 10px;
    }
  }

  .course-nav-right{
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;

    svg.icon{
      width: 34px;
      height: 34px;

      circle {
        fill: $new-blue;
      }

      &:hover {
        circle {
          fill: lighten($new-blue, 12%);
        }
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 12px;

      svg.icon {
        width: 22px;
        height: 22px;
      }
    }
  }
}
