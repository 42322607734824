// FOOTER.SCSS

.footer {
  height: 200px;
  width: 100%;
  background: $black;
  color: $white;
  font-size: 12px;

  a {
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  h5 {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 14px;
    font-family: 'Roboto Medium', Helvetica, sans-serif;
  }

  ul {
    list-style-type: none;
    margin-left: -38px;
  }

  .image {
    min-height: 130px;

    img {
      height: auto;
      width: 105px;
    }
  }
}
